import React from 'react'
import * as styles from './index.module.scss';

const WebComponentPartialCategoriesItemCard = (props) => {
    const { title, description, banner } = props;
    return (
        <div className={styles.card} style={{ backgroundImage: `url(${banner})` }}>
            <div className={styles.tint}>
            </div>
            <div className={styles.content}>
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </div>
    );
}

export default WebComponentPartialCategoriesItemCard;