import React, { useEffect } from "react";
import * as styles from "./index.module.scss";
import logoBlack from "$assets/images/logo_black.png";
import logoWhite from "$assets/images/logo_white.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { IconSearch } from "../../../../../components/icons/search";
import { IconHeart } from "../../../../../components/icons/heart";
import { IconCart } from "../../../../../components/icons/cart";
import ButtonMain from "../../../../../components/buttons/Main/index";
import { useDispatch, useSelector } from "react-redux";
import { MainMenuModes } from "../../../../../../redux/features/app";
import { Badge } from "antd";
import ButtonOutline from "../../../../../components/buttons/Outline/index";
import { logout } from "../../../../../../redux/features/authentication";
import { useState } from "react";
import { IconMenu } from "../../../../../components/icons/menu";
import { IconChevronLeft } from "../../../../../components/icons/chevron-left";

export const WebComponentHeader = (props) => {
  // router
  const navigate = useNavigate();
  const location = useLocation();

  // state
  const [showMenu, setShowMenu] = useState(false);

  // redux
  const dispatch = useDispatch();
  const token = useSelector((store) => store.authentication.token);
  const cart = useSelector((store) => store.cart);

  // redux
  const mode = useSelector((state) => state.app.mainMenu.mode);

  // effects
  useEffect(() => {
    setShowMenu(false);
  }, [location]);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div
      className={`${styles.header} ${
        mode == MainMenuModes.transparent ? styles.transparent : ""
      }`}
    >
      <div className="container">
        <div className="d-flex align-items-center" style={{ height: "80px" }}>
          {/* <img
            src={mode == MainMenuModes.transparent ? logoWhite : logoBlack}
            className={`${styles.logo}`}
            alt="Grace Products"
          /> */}


         <NavLink
              to="/home"
              className={({ isActive }) => (isActive ? styles.active : null)}
            >
                 <img
            src={mode == MainMenuModes.transparent ? logoWhite : logoBlack}
            className={`${styles.logo}`}
            alt="Grace Products"
          />
            </NavLink>

            <div className={`flex align-items: flex-end ${styles.extra} d-lg-none  ${
              mode == MainMenuModes.transparent
                ? "btn-primary text-white"
                : "btn-link"
            } ${styles.toggle}`}>
              <div className="nav-item">
                <IconSearch height="20" width="20" />
              </div>
              <div className="nav-item">
                <IconHeart height="20" width="20" />
              </div>
              <div className="navbr-item" onClick={() => navigate("/cart")}>
                <Badge
                  style={{ backgroundColor: "#52c41a" }}
                  count={cart.items.length}
                >
                  <IconCart
                    height="20"
                    width="20"
                    color={
                      mode == MainMenuModes.transparent ? "white" : "black"
                    }
                  />
                </Badge>
              </div>
            </div>

          <button
            className={`d-block d-lg-none btn ${
              mode == MainMenuModes.transparent
                ? "btn-primary text-white"
                : "btn-link"
            } ${styles.toggle}`}
            onClick={() => setShowMenu(!showMenu)}
          >
            <IconMenu height={20} width={20} />
          </button>

        


          <div
            className={`d-lg-none ${styles.sidemenu} ${
              showMenu ? styles.show : styles.hide
            }`}
          >
            <div
              onClick={() => setShowMenu(false)}
              className="d-flex align-items-center"
              style={{ fontSize: "12px" }}
            >
              <IconChevronLeft height="15" width="15" /> Close
            </div>
            <div className="mt-2"></div>
            <h3>Menu</h3>
            <div className="mt-3"></div>
            <NavLink
              to="/home"
              className={({ isActive }) => (isActive ? styles.active : null)}
            >
              Home
            </NavLink>
            <NavLink
              to="/shop"
              className={({ isActive }) => (isActive ? styles.active : null)}
            >
              Shop All
            </NavLink>
            <NavLink
              to="/contact"
              className={({ isActive }) => (isActive ? styles.active : null)}
            >
              Contact Us
            </NavLink>

            <div className="mt-auto"></div>

            {token ? (
              <>
                <ButtonOutline
                  className="text-dark"
                  onClick={() => navigate("/my-account")}
                >
                  My Account
                </ButtonOutline>
                <div className="py-2"></div>
                <ButtonOutline
                  className="text-dark"
                  onClick={() => handleLogout()}
                >
                  Logout
                </ButtonOutline>
              </>
            ) : (
              <>
                <ButtonMain onClick={() => navigate("/auth")}>Login</ButtonMain>
                <div className="py-1"></div>
                <ButtonMain onClick={() => navigate("/auth/register")}>Sign Up</ButtonMain>
              </>
            )}
          </div>
          <div className="d-none d-lg-flex align-items-center  w-100">
            <div className={`${styles.menu} flex-1`}>
              <NavLink
                to="/home"
                className={({ isActive }) => (isActive ? styles.active : null)}
              >
                Home
              </NavLink>
              <NavLink
                to="/shop"
                className={({ isActive }) => (isActive ? styles.active : null)}
              >
                Shop All
              </NavLink>
              <NavLink
                to="/contact"
                className={({ isActive }) => (isActive ? styles.active : null)}
              >
                Contact Us
              </NavLink>
            </div>

            <div className={`flex ${styles.extra}`}>
              <div className="nav-item">
                <IconSearch height="20" width="20" />
              </div>
              <div className="nav-item">
                <IconHeart height="20" width="20" />
              </div>
              <div className="navbr-item" onClick={() => navigate("/cart")}>
                <Badge
                  style={{ backgroundColor: "#52c41a" }}
                  count={cart.items.length}
                >
                  <IconCart
                    height="20"
                    width="20"
                    color={
                      mode == MainMenuModes.transparent ? "white" : "black"
                    }
                  />
                </Badge>
              </div>
            </div>
            {token ? (
              <>
                <ButtonOutline
                  className={
                    mode == MainMenuModes.transparent
                      ? "text-light"
                      : "text-dark"
                  }
                  onClick={() => navigate("/my-account")}
                >
                  My Account
                </ButtonOutline>
                <div className="px-1"></div>
                <ButtonOutline
                  className={
                    mode == MainMenuModes.transparent
                      ? "text-light"
                      : "text-dark"
                  }
                  onClick={() => handleLogout()}
                >
                  Logout
                </ButtonOutline>
              </>
            ) : (
              <>
                <ButtonMain onClick={() => navigate("/auth")}>Login</ButtonMain>
                <div className="px-1"></div>
                <ButtonMain onClick={() => navigate("/auth/register")}>Sign Up</ButtonMain>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
