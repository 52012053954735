import { useSelector } from "react-redux";

const { createSlice } = require("@reduxjs/toolkit");
//const products = useSelector((store) => store.product.products);

const initialState = {
    items: [],
    total: 0,
}

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart: (state, action) => {
            if (action.payload) {
                let product = action.payload;

                // check  if product already exists
                if (state.items.some(item => item.product.id == product.id)) {
                    let index = state.items.findIndex(item => item.product.id == product.id);
                    state.items[index] = {
                        product: product,
                        quantity: state.items[index].quantity + 1,
                    };
                } else {
                    // the product does not exist
                    state.items.push({
                        product: product,
                        quantity: 1
                    });
                }

                // console.log("product price", state.items.map((item) => item.skus[0].price));
                if (state.items.length > 0) {
                    state.total = state.items.reduce((sum, item) => {
                        if (item.product && item.product.skus && item.product.skus.lenght > 0) {
                            console.log(item.product.skus[0].price);
                            return sum + item.product.skus[0].price;
                        } else {
                            return sum;
                        }
                    }, 0);
                }


            }
        },
        incrementQuantity: (state, action) => {
            console.log("actionn payload", action.payload);
            const index = state.items.findIndex((item) => item.product.id === action.payload);
            if (index == -1) return;

            state.items[index].quantity++;
        },
        decrementQuantity: (state, action) => {
            const item = state.items.find((item) => item.product.id === action.payload);
            if (!item) return;

            if (item.quantity === 1) {
                item.quantity = 1
            } else {
                item.quantity--;
            }
        },
        updateQuantity: (state, action) => {
            console.log("action payload", action.payload);
            const index = state.items.findIndex((item) => item.product.id === action.payload.product.id);
            if (action.payload.quantity < 1) return;

            if (index == -1) {
                state.items.push({
                    product: action.payload.product,
                    quantity: action.payload.quantity
                });
            } else {
                state.items[index].quantity = action.payload.quantity;
            }
        },
        removeItem: (state, action) => {
            const removeItem = state.items.filter((item) => item.product.id !== action.payload);
            state.items = removeItem;
        },



    }

});

export const { addToCart, incrementQuantity, decrementQuantity, removeItem, updateQuantity, u } = cartSlice.actions;
export default cartSlice.reducer;