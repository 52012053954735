// import React from 'react'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import disinfectantBanner from '$assets/images/categories/disinfectant.jpeg';
import healthBanner from '$assets/images/categories/health.jpeg';
import hygineBanner from '$assets/images/categories/hygine.jpeg';
import beautyBanner from '$assets/images/categories/beauty.jpeg';
import WebComponentPartialCategoriesItemCard from './item';
import * as styles from './index.module.scss'


import { useDispatch, useSelector } from 'react-redux';
import { getProductCategory } from '../../../../../../redux/features/product';
import { getProducts } from '../../../../../../redux/features/product';


const WebComponentPartialCategories = () => {
    const dispatch = useDispatch();
    const [selectCategory, setSelectedCategory] = useState([]);

    const categories = useSelector((store) => store.product.categories)
    useEffect(() => {
        dispatch(getProductCategory());
    }, [])

  return (
    <div>
        <h1 className='text-center'>Shop by Categories</h1>
        <p className='text-center'>Its easy to find what you are looking for through categories. Explore even more with Grace Products.</p>

        <div className='row'>


        {categories.map((value) => (
        
//         <div key={value.id}>

//       <NavLink onClick={() => handleCategoryChange(value.id)}>{value.name}</NavLink>


      
// </div>


<div className={`col-lg-4 mt-2 ${styles.category}`}>
<NavLink to={`/shop?category=${value.name}`}>
   <WebComponentPartialCategoriesItemCard title={value.name} description={value.description} banner={disinfectantBanner} />
</NavLink>
</div>




))}


            {/* <div className={`col-lg-8 ${styles.category}`}>
               <NavLink to="/shop?category=disinfectant">
                  <WebComponentPartialCategoriesItemCard title="Disinfectant" description="For Cleaning Purpose" banner={disinfectantBanner} />
               </NavLink>
            </div>
            <div className={`col-lg-4 ${styles.category}`}>
                <NavLink to="/shop?category=health%20products">
                    <WebComponentPartialCategoriesItemCard title="Health" description="Skin & Hair Products" banner={healthBanner} />
                </NavLink>    
            </div> */}
        </div>

        {/* <div className='row mt-3'>
            <div className={`col-lg-4 ${styles.category}`}>
                <NavLink to="/shop?category=beauty">
                   <WebComponentPartialCategoriesItemCard title="Beauty" description="Skin & Hair Products" banner={beautyBanner} />
                </NavLink>
            </div>
            <div className={`col-lg-8 ${styles.category}`}>
                <NavLink to="/shop?category=hygine">
                    <WebComponentPartialCategoriesItemCard title="Hygine" description="Removing & Killling Gems" banner={hygineBanner} />
                </NavLink>
                  
            </div>
        </div> */}
    </div>
  )
}

export default WebComponentPartialCategories;