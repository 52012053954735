import React from 'react'

export const WebHomeComponentTestminials = () => {
  return (
  <div className="container-fluid mt-3 py-5 bg-light">
        <h2 className='text-center'>Testimonial</h2>
        <p className='text-center'>Mirum est notare quam littera gothica quam nunc putamus parum claram!</p>

    <div className="row d-flex justify-content-center">
      <div className="col-md-12">
        <div className="text-center mb-4 pb-2">
          <i className="fas fa-quote-left fa-3x text-white"></i>
        </div>

        <div className="">
          <div className="px-4 py-5">
            {/* <!-- Carousel wrapper --> */}
            <div id="carouselDarkVariant" className="carousel slide carousel-dark" data-mdb-ride="carousel">
              {/* <!-- Indicators --> */}
              {/* <!-- Inner --> */}
              <div className="carousel-inner pb-5">
                {/* <!-- Single item --> */}
                <div className="carousel-item active">
                  <div className="row d-flex justify-content-center">
                    <div className="col-lg-10 col-xl-8">
                      <div className="row">
                        <div className="col-lg-4 d-flex justify-content-center">
                          <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                            className="rounded-circle shadow-1 mb-4 mb-lg-0" alt="woman avatar" width="150"
                            height="150" />
                        </div>
                        <div
                          className="col-9 col-md-9 col-lg-7 col-xl-8 text-center text-lg-start mx-auto mx-lg-0">
                          <h5 className="mb-4">Maria Smantha</h5>
                          <p className="mb-0 pb-3">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. A
                            aliquam amet animi blanditiis consequatur debitis dicta
                            distinctio, enim error eum iste libero modi nam natus
                            perferendis possimus quasi sint sit tempora voluptatem. Est,
                            exercitationem id ipsa ipsum laboriosam perferendis.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Inner --> */}

              {/* <!-- Controls --> */}
              <button className="carousel-control-prev" type="button" data-mdb-target="#carouselDarkVariant"
                data-mdb-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-mdb-target="#carouselDarkVariant"
                data-mdb-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            {/* <!-- Carousel wrapper --> */}
          </div>
        </div>

        <div className="text-center mt-4 pt-2">
          <i className="fas fa-quote-right fa-3x text-white"></i>
        </div>
      </div>
    </div>
  </div>
  )
}
