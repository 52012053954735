import React, { useState } from 'react'
import * as styles from './index.module.scss';
import banner from '$assets/images/contact_banner.png';

export const WebHomeComponentContact = () => {

  const [email, setEmail] = useState('')

  // handlers
  const handleSubmit = () => {
    alert(email);
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }
  return (
    <div className={styles.wrapper} style={{ backgroundImage: `url(${banner})` }}>
        <div className={styles.overlay}></div>
        <div className={styles.container}>
          <div className="row">
            <div className="col-sm-2">

            </div>
            <div className="col-sm-8">
                <h2 className='text-center m-3'>Contact US</h2>
              <div className={styles.box}>
                  <form>
                        <div class="mb-3">
                        <label for="formGroupExampleInput">Name</label>
                        <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Example input" />
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">Email</label>
                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={handleEmailChange} />
                        </div>
                        <div class="mb-3">
                        <label for="exampleFormControlTextarea1">Your Message</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                        <button type="submit" class="btn btn-primary" onClick={handleSubmit}>Submit</button>
                  </form>
                </div>
            </div>
            <div className="col-sm-2">

            </div>
          </div>

        </div>
    </div>
  )
}
