import React from 'react'

export const IconDash = (props) => {
    return (
        <svg viewBox="0 0 16 2" fill="currentColor" xmlns="http://www.w3.org/2000/svg"{...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.941406C0 0.527193 0.421444 0.191406 0.941322 0.191406H15.0586C15.5785 0.191406 15.9999 0.527193 15.9999 0.941406C15.9999 1.35562 15.5785 1.69141 15.0586 1.69141H0.941322C0.421444 1.69141 0 1.35562 0 0.941406Z" />

        </svg>

    )
}
