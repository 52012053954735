import React, {useEffect, useState} from 'react'
import productSingle from '$assets/images/productSingle.png';
import productSingleA from '$assets/images/productSingleA.png';
import {IconHeart} from '../../../../../components/icons/heart';
import WebComponentItemProduct from '../../items/product'
import {useParams} from 'react-router-dom';
import {viewProduct} from '../../../../../../redux/features/product';
import {useDispatch, useSelector} from 'react-redux';
import GraceApi from '../../../../../../api/grace/endpoint';
import {IconCart} from "../../../../../components/icons/cart";
import {updateQuantity} from "../../../../../../redux/features/cart";

const WebComponentPartialSingleProducts = (props) => {
    // params
    const params = useParams();

    // state
    const [qty, setQty] = useState(1);

    // redux
    const dispatch = useDispatch();
    const product = useSelector((store) => store.product.view.data);
    const item = useSelector((store) => store.cart.items)?.find(_item => _item.product.id == params.id ? _item : null);

    useEffect(() => {
        setQty(item?.quantity == null ? 1 : item.quantity);
    }, [item]);


    useEffect(() => {
        if (!params.id) return
        dispatch(viewProduct(params.id));
    }, [params.id]);

    return (
        <div className='container'>
            <div className="row">
                <div className="col-sm-7">
                    <div className="d-flex">
                        <div className="d-flex flex-column me-3">
                            {/* <img className='mb-2 img-fluid' height="300px" width="300px" src={GraceApi.domain + (product && product.skus && product.skus[0].featured_image)} alt="" /> */}
                            {/* <img className='mb-2 img-fluid' src={productSingleA} alt="" />
                      <img className='mb-2 img-fluid' src={productSingleA} alt="" /> */}
                        </div>
                        <div>
                            <img className='img-fluid'
                                 src={GraceApi.domain + (product && product.skus && product.skus[0].featured_image)}
                                 alt=""/>
                        </div>
                    </div>

                </div>

                <div className="col-sm-5">
                    <div className='d-flex flex-column '>
                        <h4>{product.name}</h4>
                        <h3>TSH. {product && product.skus && parseFloat(product.skus[0].price).toLocaleString()}/=</h3>
                        <p className='mt-3'>
                            {product && product.skus && product.skus[0].description}
                        </p>
                        <span className='text-muted mb-3'>(1Reviews) Write a review</span>

                        <div className="container mt-3">
                            <div className="row">
                                <div className="col">
                                    {item ? <p><IconCart height={20} width={20}/> -- <strong
                                        className="text-bold">{item.quantity}</strong> Item(s) in the Cart</p> : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="d-flex col-6">
                                    <input type="number" class="form-control" placeholder="Quantity"
                                           aria-label="Quantity" onChange={(e) => setQty(e.target.value)} value={qty}/>
                                </div>
                                <div className="d-flex col-6">
                                    {item
                                        ? <button type="button" className="btn btn-warning btn-lg text-nowrap"
                                                  onClick={() => dispatch(updateQuantity({
                                                      product: product,
                                                      quantity: qty
                                                  }))}>Update Cart</button>
                                        : <button type="button" className="btn btn-success btn-lg text-nowrap"
                                                  onClick={() => dispatch(updateQuantity({
                                                      product: product,
                                                      quantity: qty
                                                  }))}>Add To Cart</button>}
                                </div>
                            </div>
                            <div className='d-flex my-5'>
                                <div><IconHeart height="20" width="20"/></div>
                                <p className='mx-2'>Add to Wish List</p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default WebComponentPartialSingleProducts
