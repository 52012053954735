import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getProductCategory } from '../../../../../redux/features/product';
import { getProducts } from '../../../../../redux/features/product';
import { getAgents } from '../../../../../redux/features/agent';
import WebComponentItemProduct from '../../components/items/product/index';
import {NavLink, useSearchParams} from 'react-router-dom';

export const WebProductsPage = (props) => {
    const dispatch = useDispatch();


    // state
    const [selectCategory, setSelectedCategory] = useState([]);
    const [activeCategory, setActiveCategory] = useState(null);

    const [query, setQuery] = useState("");

    //Function to get filterd list

    const getFilteredCategory = () => {
        if (!setSelectedCategory) {
            return products;
        }
        return products.filter((product) => product.category === setSelectedCategory);
    }

    // handlers

    // const handleCategoryChange = (event) => {
    //    setSelectedCategory(event.target.value);
    //}

    useEffect(() => {
        dispatch(getProductCategory());
    }, [])

    useEffect(() => {
        dispatch(getProducts());
    }, [])

    useEffect(() => {
        dispatch(getAgents());
    }, [])


    const categories = useSelector((store) => store.product.categories)

    const products = useSelector((store) => store.product.products)

    const agents = useSelector((store) => store.agent.agents)

    const [searchParams ] = useSearchParams();
    const queryCategory = searchParams.get("category");

    useEffect(() => {
        if (!queryCategory) return;
        if (!categories) return;
        let _category = categories.find(c => c.name.toLowerCase() === queryCategory.toLowerCase());
        if (_category) {
            handleCategoryChange(_category.id);
        }
    }, [queryCategory, categories])


    const handleCategoryChange = (id) => {
        const data = products.filter((product) => product.categories.map((value) => value.id == id));

        setActiveCategory(id);
        console.log("value selected is:", data);

    }


    return (
        <div className="container my-5 py-5">
            <div className="row">
                <div className="col-lg-3"></div>
                <div className="col-lg-9">
                    <h1>Shop All</h1>
                    <p>Home - Shop All</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3">
                    <div className="mt-5">
                        <input type="text" onChange={(e) => setQuery(e.target.value)} className="form-control" placeholder='Search' />

                        <h2 className='mt-5 mb-3'>All Categories</h2>
                        <NavLink onClick={() => handleCategoryChange()}>All</NavLink>
                        {categories.map((value) => (<div key={value.id}>

                            <NavLink onClick={() => handleCategoryChange(value.id)}>{value.name}</NavLink>
                        </div>))}

                        {/* <h2 className='mt-5 mb-3'>Shops</h2> */}

                        {agents.map((value) => (<div key={value.id}> <p>{value.company_name}</p>

                        </div>))}

                    </div>
                </div>

                <div className="col-lg-9">
                    <div className="row mt-5">
                        {products.filter(p => activeCategory ? p.categories.some(c => c.id == activeCategory) : true).filter(p => query ? p.name.toLowerCase().includes(query.toLowerCase()) : true ).map((value) => (
                            <div key={value.id} className="col-lg-4">
                                <WebComponentItemProduct product={value} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
