import React from 'react'

export const IconCicleCheck = (props) => {
  return (
    <svg viewBox="0 0 121 121" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clip-path="url(#clip0_14_2290)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 60.5C0 44.4544 6.37409 29.066 17.72 17.72C29.066 6.37409 44.4544 0 60.5 0C76.5456 0 91.934 6.37409 103.28 17.72C114.626 29.066 121 44.4544 121 60.5C121 76.5456 114.626 91.934 103.28 103.28C91.934 114.626 76.5456 121 60.5 121C44.4544 121 29.066 114.626 17.72 103.28C6.37409 91.934 0 76.5456 0 60.5H0ZM57.0475 86.394L91.8793 42.8501L85.5873 37.8165L55.8859 74.9313L34.848 57.4024L29.6853 63.5976L57.0475 86.4021V86.394Z" fill="currentColor"/>
        </g>
        <defs>
        <clipPath id="clip0_14_2290">
        <rect width="121" height="121" fill="white"/>
        </clipPath>
        </defs>
    </svg>

  )
}
