
import React from 'react'
import { IconSearch } from '../../../../../../components/icons/search';
import { ComponentDataTable } from '../../../table/Datatable';
import * as styles from './index.module.scss';

export const DashboardComponentMainBoard = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.search}>
                <div className={`input-group mb-3 ${styles.inputIcons}`}>
                    <div className="input-group-prepend">
                        <span className="input-group-text"> <IconSearch width="20px" height="20px" /></span>
                    </div>
                    <input type="text" className={`form-control ${styles.inputField}`} placeholder="Search...." aria-describedby="basic-addon1" />
                </div>


            </div>
            <ComponentDataTable />
        </div>
    )
}
