class GraceApi {
    static domain = "https://api.graceproducts.co.tz"
    // static domain = "http://localhost:8000"

    // static base_url = "https://www.graceproducttz.com/api"
    static base_url = `${this.domain}/api`;

    //login
    static login = `${this.base_url}/auth/login`;
    static register = `${this.base_url}/auth/register`;

    // verification
    static requestEmailVerification = `${this.base_url}/auth/request-email-verification`;
    static verifyEmail = `${this.base_url}/auth/verify-email`;
    static verifyPhoneOwnership = `${this.base_url}/auth/verify-phone-ownership`;

    //products
    static products = `${this.base_url}/product/products`;
    static productCreate = `${this.base_url}/product/products`;
    static productUpdate = (id) => `${this.base_url}/product/products/${id}`;
    static productShow = (id) => `${this.base_url}/product/products/${id}`;
    static productDelete = (id) => `${this.base_url}/product/products/${id}`;

    //PRODUCTcAREGORY
    static productCategory = `${this.base_url}/product/productCategories `;
    static productCategoryCreate = `${this.base_url}/product/productCategories `;
    static productCategoryShow = (id) => `${this.base_url}/product/productCategories/${id} `;
    static productCategoryUpdate = (id) => `${this.base_url}/product/productCategories/${id} `;
    //productSku
    static productSku = (id) => `${this.base_url}/product/products/${id}/productSkus`;
    static productSkuCreate = (id) => `${this.base_url}/product/products/${id}/productSkus`;
    //productOptions
    static productOptionCreate = (id) => `${this.base_url}/product/products/${id}/productOptions`;

    //orders
    static orders = `${this.base_url}/orders`;
    static orderCreate = `${this.base_url}/checkout`;
    static orderShow = (id) => `${this.base_url}/orders/${id}`;
    static orderDelete = (id) => `${this.base_url}/orders/${id}`;
    static orderUpdate = (id) => `${this.base_url}/orders/${id}`;
    static orderAssignAgent = `${this.base_url}/orders/assign-agent`;
    

    // payments
    static orderCreatePayment = (id) => `${this.base_url}/orders/${id}/pay`;
    static registrationCreatePayment = (id) => `${this.base_url}/registration-orders/${id}/pay`;
    static checkPaymentStatus = (id) => `${this.base_url}/payments/${id}/status`;

    // payouts
    static payoutRequests = `${this.base_url}/payments/payout-requests`;
    static payoutRequestApprove = (id) => `${this.base_url}/payments/payout-requests/${id}/approve`;

    //agents
    static agents = `${this.base_url}/agents `;
    static agentCreate = `${this.base_url}/agents`;
    static agentShow = (id) => `${this.base_url}/agents/${id}`;
    static agentDelete = (id) => `${this.base_url}/agents/${id}`;
    static agentUpdate = (id) => `${this.base_url}/agents/${id}`;

    //members
    static members = `${this.base_url}/member/members`;
    static memberCreate = `${this.base_url}/member/members`;
    static memberUpdate = (id) =>`${this.base_url}/member/members/${id}`;
    static memberShow = (id) => `${this.base_url}/member/members/${id}`;
    static memberDelete = (id) =>`${this.base_url}/member/members/${id}`;

    ///users
    static users = `${this.base_url}/config/users`;
    static userCreate = `${this.base_url}/config/users`;
    static userUpdate = (id) => `${this.base_url}/config/users/${id}`;
    static userShow = (id) => `${this.base_url}/config/users/${id}`;
    static userDelete = (id) => `${this.base_url}/config/users/${id}`;

    //merchant
    static merchants = `${this.base_url}/organization/organizations`;
    static merchantCreate = `${this.base_url}/organization/organizations`;
    static merchantShow = (id) => `${this.base_url}/organization/organizations/${id}`;
    static merchantUpdate = (id) => `${this.base_url}/organization/organizations/${id}`;
    static merchantDelete = (id) => `${this.base_url}/organization/organizations/${id}`;

    //merchantType
    static merchantType = `${this.base_url}/organization/organizationTypes`;
    static merchantTypeCreate = `${this.base_url}/organization/organizationTypes`;
    static merchantTypeUpdate = (id) => `${this.base_url}/organization/organizationTypes/${id}`;
    static MerchantTypeShow = (id) => `${this.base_url}/organization/organizationTypes/${id}`;
    static merchantTypeDelete = (id) => `${this.base_url}/organization/organizationTypes/${id}`;

    // location
    static countries = `${this.base_url}/location/countries`;
    static country = (id) => `${this.base_url}/location/countries/${id}`;
    static regions = (id) => `${this.base_url}/location/countries/regions/${id}`;

    ///paymethod method
    static paymentMethods = `${this.base_url}/payment-methods `;
    static paymentMethodCreate = `${this.base_url}/payment-methods`;
    static paymentMethodUpdate = (id) => `${this.base_url}/payment-methods/${id}`;
    static paymentMethodShow = (id) => `${this.base_url}/payment-methods/${id}`;
    static paymentMethodDelete = (id) => `${this.base_url}/payment-methods/${id}`;

    //mobile operations;
    static mobileOperators = `${this.base_url}/mobile-operators`;
    static mobileOperatorCreate = `${this.base_url}/mobile-operators`;
    static mobileOperatorShow = (id) => `${this.base_url}/mobile-operators/${id}`;
    static mobileOperatorUpdate = (id) => `${this.base_url}/mobile-operators/${id}`;
    static mobileOperatorDelete = (id) => `${this.base_url}/mobile-operators/${id}`;

    //role management;
    static roles = `${this.base_url}/config/roles`;
    static roleCreate = `${this.base_url}/config/roles`;
    static roleShow = (id) => `${this.base_url}/config/roles/${id}`;
    static roleUpdate = (id) => `${this.base_url}/config/roles/${id}`;
    static roleDelete = (id) => `${this.base_url}/config/roles/${id}`;

    //permission management;
    static permissions = `${this.base_url}/config/permissions`;

}
export default GraceApi;